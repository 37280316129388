import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Mixin from "./mixin"

import HBtn from "./components/button/index.vue"
import HTitle from "./components/home-title/index.vue"
import HSecond from "./components/second-banner/index.vue"
import CTitle from "./components/center-title/index.vue"
import MbMenu from "./components/mb-menu/index.vue"
import TopMenu from "./components/mb-menu/detaile-munu.vue"
import BreadNav from "./components/b-bread/index.vue"
import Empty from "./components/empty/index.vue"
import HLoading from "./components/loading/index.vue"

//import GeminiScrollbar from "vue-gemini-scrollbar"
import { Swipe, SwipeItem, Toast, Lazyload } from 'vant';
import axios from 'axios'

import VueTouch from 'vue-touch'
Vue.use(VueTouch, {name: 'v-touch'})


//import VConsole from 'vconsole'
//let vConsole = new VConsole() // 初始化
// if(process.env != 'production') {
// 	let vConsole = new VConsole() // 初始化
// }

// import { Swipe, SwipeItem } from 'vant';
Vue.component('h-btn', HBtn)
Vue.component('h-title', HTitle)
Vue.component('h-second', HSecond)
Vue.component('c-title', CTitle)
Vue.component('mb-menu', MbMenu)
Vue.component('top-menu', TopMenu)
Vue.component('bread-nav', BreadNav)
Vue.component('Empty', Empty)
Vue.component('h-loading', HLoading)

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Toast)
Vue.use(Lazyload, {
  lazyComponent: true
});

Vue.prototype.$http = axios.create({
  timeout: 1000 * 8
})

if (process.env.NODE_ENV === 'production') {
  Vue.prototype.$http.defaults.baseURL = 'https://api.innosoc.com/api/'
}

//Vue.use(GeminiScrollbar)
//Vue.use(AMap)


import "./assets/css/index.less"
//import "view-design/dist/styles/iview.css"


Vue.prototype.$http.interceptors.response.use((res) => {
  if (res.data.code === 200) {
    if (res.data) {
      return res.data
    }
  } else {
    return Promise.reject(res)
  }
}, (error) => {
  return Promise.reject(error) 
})


Vue.prototype.$isMobile = function () {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

Vue.config.productionTip = false

Vue.mixin(Mixin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
