<template>
  <div></div>
  <!-- <overlay :show="show">
    <div class="waper-loading">
      <loading type="spinner" color="#1989fa">加载中...</loading>
    </div>
  </overlay> -->
</template>

<script>
import { Overlay, Loading } from 'vant';
export default {
  name: 'h-loading',
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Overlay,
    Loading
  }
}
</script>

<style lang="less" scoped>
.waper-loading{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 5000;
}
</style>