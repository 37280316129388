<template>
  <div class="null-box" v-if="showEmpty">
    <img v-lazy="img" alt="">
    <span>{{$store.state.language == 'CN' ? '空空如也' : 'absolutely empty'}}</span>
    <h-btn @onclick="push('Home')" :text="$store.state.language == 'CN' ?  '返回首页' : 'Home page'"></h-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: 'https://innosoc2-1308345741.cos.ap-guangzhou.myqcloud.com/product%2F120109.png'
    }
  },
  props: {
    showEmpty: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.null-box{
  width: 100%;
  text-align: center;
  img{
    display: inline-block;
    width: 382px;
    margin: 0 auto;
  }
  span{
    display: block;
    font-size: 16px;
    color: #999999;
    text-align: center;
    padding-bottom: 20px;
  }
}
</style>