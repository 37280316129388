<template>
  <div class="nav">
    <div class="img-log">
      <img :src="$store.state.webBasicSet.icon" alt="" @click="push('Home')" />
    </div>
    <div class="nav-list" v-if="$store.state.clientWidth > 1200">
      <span
        class="nav-li"
        v-for="(item, index) in $store.state.newNavlist"
        :class="{ active: currentName == item.code }"
        :key="index"
        @click="goto(index, item)"
        @mouseenter="seenter(item)"
        @mouseleave="leave(item)"
      >
        {{ $store.state.language == 'CN' ? item.name : item.enname }}
        <i class="line"></i>
      </span>
    </div>

    <div class="link-box">
      <div class="language-btn">
        <Dropdown trigger="click" @on-click="changeLan">
          <span v-if="$store.state.language == 'CN'">{{$store.state.language == 'CN' ? '中文' : 'CN'}}</span>
          <span v-if="$store.state.language == 'EN'">{{$store.state.language == 'EN' ? 'EN' : '英文'}}</span>
          <Dropdown-menu slot="list">
              <Dropdown-item name="CN"> {{ $store.state.language == 'CN' ? '中文' : 'CN'}}</Dropdown-item>
              <Dropdown-item name="EN">{{$store.state.language == 'CN' ? '英文' : 'EN'}}</Dropdown-item>
          </Dropdown-menu>
        </Dropdown>
      </div>
      <div
        class="link-menu"
        v-if="$store.state.clientWidth <= 1200"
        @click="showMenu"
      ></div>
    </div>
    <!-- 子菜单 -->
    <div
      class="mark"
      v-show="sunMenu"
      v-if="$store.state.clientWidth > 1200"
      @mouseleave="leave"
      @mouseenter="sunMenu = true"
    >
      <div class="container list">
        <div class="item" v-for="(item, kk) in children" :key="kk" :style="{'margin-right': $store.state.language == 'EN' ? '80px' : ''}">
          <div class="t" :style="{ 'background-image': item.image ? 'url(' + item.image + ')' : ' ', }" v-if="item.type != 3" @click="change(item, kk)">
            {{ $store.state.language == 'CN' ? item.name : item.enname }}
          </div>
          <div class="li" @click="seenTo(el, item)" v-for="(el, l) in item.children" :key="l" v-if="item.type != 3">
            {{ $store.state.language == 'CN' ? el.name : el.enname }}
          </div>
          <div class="li" @click="seenTo(item)" v-if="item.type == 3">
            {{ $store.state.language == 'CN' ? item.name : item.enname }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dropdown, DropdownMenu, DropdownItem} from "view-design"
export default {
  name: "h-nav",
  data() {
    return {
      currentName: "Home",
      sunMenu: false,
      childer: [],
    };
  },
  watch: {
    $route: {
      handler(v1, v2) {
        this.currentName = v1.matched[0].name;
      },
      deep: true,
    },
  },
  methods: {
    changeLan(name) {
      if(name == this.$store.state.language) {
        return false;
      } else {
        this.$store.dispatch('set_language', name)
      }
    },
    showMenu() {
      let list = this.$store.state.newNavlist.filter(item => item.name != '首页')
      this.$store.dispatch("set_mbMenuList", list);
      setTimeout(() => {
        this.$store.dispatch("set_showMenu", true);
      }, 30);
    },
    seenTo(el) {
      this.$store.dispatch('set_breadNavList', {name: el.name, enname: el.enname});
      this.$router.push({
        path: el.uri
      })
      this.sunMenu = false;
    },
    change(item, kk) {
      this.$store.dispatch('set_breadNavList', {name: item.name, enname: item.enname});
      this.$router.push({
        path: item.uri
      })
      this.sunMenu = false;
    },
    goto(index, item) {
      this.$store.dispatch('set_breadNavList', {name: item.name, enname: item.enname});
      this.targetIndex = index;
      this.$router.push({
        path: item.uri
      })
      this.sunMenu = false;
    },
    seenter(item) {
      if (item.children.length) {
        this.sunMenu = true;
        this.children = item.children;
      }
    },
    leave() {
      this.sunMenu = false;
    },
  },
  components: {
    Dropdown, DropdownMenu, DropdownItem
  }
};
</script>

<style lang="less" scoped>
@import url("./nav.less");
</style>

