<template>
  <div class="title">
    {{text}}
  </div>
</template>

<script>
export default {
  props: ['text']
}
</script>

<style lang="less" scoped>
.title{
  font-size: 30px;
  color: #333333;
  text-align: center;
  font-weight: bold;
  padding-top: 85px;
}

@media screen and (max-width: 800px) {
  .title{
    font-size: 18px;
    padding-top: 30px;
  }
}
</style>