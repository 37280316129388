<template>
  <div id="app" ref="APP">
    <h-nav v-show="showNave"></h-nav>
    <router-view class="background"/>
    <h-footer></h-footer>
    <mb-menu></mb-menu>
    <BackTop :height="150" :bottom="60">
      <div class="BackTop-top">
        <i class="ivu-icon ivu-icon-md-arrow-up"></i>
      </div>
    </BackTop>
  </div>
</template>

<script>
import { BackTop } from "view-design"
import HNav from "components/nav/nav.vue"
import HFooter from "components/footer/footer.vue"
export default {
  data() { 
    return {
      showNave: true,
    }
  },
  components: {
    HNav,
    HFooter,
    BackTop
  },
  created() {
    // banner
    this.getBannerList();
    // 横幅
    //this.getSubNavList();
    // 首页 详情
    this.getHomeDetail();
    // 网站设置
    this.getWebSet();
    // 导航
    this.getNavList();
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch('set_clientWidth', document.querySelector('#app').clientWidth);
      this.orient();
      window.onresize = () => {
        this.$store.dispatch('set_clientWidth', document.querySelector('#app').clientWidth);
        this.orient();
      }

      if(this.$store.state.clientWidth >= 1200) {
          this.showNave = true;
        } else if(this.$store.state.clientWidth < 1200 && this.$route.name != 'Home') {
          this.showNave = false;
        } else {
          this.showNave = true;
        }
    }, 30);
  },
  watch: {
    '$route': {
      handler(v1, v2) {
        if(this.$store.state.clientWidth >= 1200) {
          this.showNave = true;
        } else if(this.$store.state.clientWidth < 1200 && v1.name != 'Home') {
          this.showNave = false;
        } else {
          this.showNave = true;
        }
      },
      deep:true
    }
  },
  methods: {
    orient() {
      let height = document.documentElement.clientHeight || document.body.clientHeight;
      let width = document.documentElement.clientWidth || document.body.clientWidth;
      if(width > height) {
        this.$store.dispatch('set_horizontalScreen', false);
      } else {
        this.$store.dispatch('set_horizontalScreen', true);
      }
    }
  }
}
</script>

<style lang="less">
*{
  margin: 0;
  padding: 0;
}
body{
  margin: 0;
  padding: 0;
}
#app {
  font-family: Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  //height: 100vh;
  overflow-x: scroll;
  position: relative;
  background: #FBFBFF;
  box-sizing: border-box;
}

.detail-01{
  font-size: 14px;
  color: #333;
  p{
    padding-top: 15px;
  }
}

.eng-text{
  text-align: left !important;
  word-break: break-all !important;
  letter-spacing: normal;
  &.padding-top{
    padding-top: 35px !important;
  }
  &.margin-top{
    margin-top: 26px !important;
  }
}

.news-detail-new{
  color: #333;
  line-height: 28px;
  p {
    text-indent: 2em !important;
    img{
      margin-left: -2em !important;
    }
  }
}
.case-detail-new{
  color: #333;
  line-height: 28px;
}
.research-detail-news{
  color: #333;
  line-height: 28px;
}

.typical-detaile-new{
  color: #333;
  line-height: 28px;
}

.ivu-select-item{
  &.line{
    text-indent: 2em;
  }
}

.ivu-select-dropdown::-webkit-scrollbar{
  width: 5px;
}

.ivu-select-dropdown::-webkit-scrollbar-track{
  //background: rgba(0, 0, 0, 0.2);
  border-radius: 2em;
}

.ivu-select-dropdown::-webkit-scrollbar-thumb{
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2em;
}

.BackTop-top{
  width: 60px;
  height: 60px;
  // background-image: url('./assets/img/top.png');
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  background: #0268DB;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  transition: all 0.25s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-icon-md-arrow-up{
    font-size: 36px;
  }
  &:hover{
    background: #1E88FF;
  }
}


@media screen and (max-width: 800px) {
  .BackTop-top{
    width: 40px;
    height: 40px;
    background: #0268DB;
    border-radius: 50%;
    text-align: center;
    transition: all 0.25s ease-in-out;
    .ivu-icon-md-arrow-up{
      font-size: 30px;
    }
     &:hover{
      background: #0268DB;
    }
  }

}
</style>
