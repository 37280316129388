<template>
  <div class="btn" @click="onclick">{{text}}</div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '了解更多'
    }
  },
  methods: {
    onclick() {
      this.$emit('onclick')
    }
  }
}
</script>

<style lang="less" scoped>
.btn{
  width: 114px;
  height: 32px;
  background: #0268DB;
  text-align: center;
  line-height: 32px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  transition: 0.2s ease-in all;
  position: relative;
  top: 5px;
  &:hover{
    background: #1E88FF;
  }
}
</style>