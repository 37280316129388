<template>
  <div class="detaile-meunu">
    <div class="btn1" @click="back"></div>
    <div class="title">
      <span v-if="text">{{text}}</span>
      <span v-else>{{$store.state.language == 'CN' ? $store.state.breadNavList.name : $store.state.breadNavList.enname}}</span>
      <!-- {{$store.state.language == 'CN' ? $store.state.breadNavList.name : $store.state.breadNavList.enname}} -->
    </div>
    <div class="btn2" @click="showMenu"></div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    showMenu() {
      let list = this.$store.state.newNavlist.filter(item => item.name != '首页')
      this.$store.dispatch('set_mbMenuList', list);
      this.$store.dispatch('set_showMenu', true);
    }
  }
}
</script>

<style lang="less">
.detaile-meunu{
  height: 52px;
  line-height: 52px;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 16px;
  color: #fff;
  z-index: 450;
  .title{
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .btn2{
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 52px;
    background-image: url('../../assets/img/tiao.png');
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: center right 15px;
  }
  .btn1{
    position: absolute;
    right: left;
    top: 0;
    width: 50px;
    height: 52px;
    background-image: url('../../assets/img/home-icon/btn03.png');
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: center left 10px;
  }
}
</style>