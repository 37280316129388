<template>
  <div class="title">
    {{text}}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '关于我们'
    }
  }
}
</script>

<style lang="less" scoped>
.title{
  font-size: 30px;
  //height: 30px;
  font-weight: bold;
  color: #333;
  position: relative;
  &::before{
    content: '';
    width: 62px;
    height: 6px;
    background: #0268DB;
    display: block;
    position: absolute;
    left: 0;
    top: -20px;
  }
}

@media screen and (max-width: 800px) {
  .title{
    font-size: 16px;
    &::before{
      content: '';
      width: 40px;
      height: 4px;
      background: #0268DB;
      display: block;
      position: absolute;
      left: 0;
      top: -8px;
    }
  }
}
</style>