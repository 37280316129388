<template>
  <div class="mb-menu" :class="{'show': $store.state.showMenu ? true : false}" @click="hideMenu">
    <div class="mb-list-menu">
      <div class="item code" @click.stop="hideMenu">INNOSOC</div>
      <div class="item home" @click.stop="goto" v-if="$route.name != 'Home'">{{$store.state.language == 'CN' ? '首页' : 'Home'}}</div>
      <div class="item" v-for="(item, index) in $store.state.mbMenuList" :key="index" @click.stop="changePage(item)">
        {{ $store.state.language == 'CN' ? item.name : item.enname }}
      </div>
    </div>
  </div>
</template>

<script>
export default { 
  data() {
    return {
      index: 0,
    }
  },
  mounted () {
  },
  methods: {
    goto() {
      this.push('Home');
      this.hideMenu();
    },
    hideMenu() {
      this.$store.dispatch('set_showMenu', false);
      setTimeout(() => {
         this.$store.dispatch('set_mbMenuList', []);
      }, 30)
    },
    changePage(item) {
      if(item.type == 1) {
        if(item.level && item.level == 2) {
          
          let obj = {
            name: item.name,
            enname: item.enname
          }
          this.$store.dispatch('set_breadNavList', obj);  

          this.$router.push({
            path: item.uri
          })

          this.hideMenu();

        } else {
          this.$store.dispatch('set_mbMenuList', item.children);
        }
      } else {
        this.$router.push({
          path: item.uri
        })
        this.hideMenu();
      }

    },
  }
}
</script>

<style lang="less" scoped>
.mb-menu{
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0,0);
  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 600;
  opacity: 0;
  transition: all 0.5s ease-in-out 0.2s !important;
  &.show{
    right: 0;
    opacity: 1;
  }
  .mb-list-menu{
    padding: 10px 20px 0 20px;
    width: 310px;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    background: #00050B;
    box-sizing: border-box;
    .item{
      font-size: 14px;
      color: #C2C2C2;
      line-height: 38px;
      background-image: url('../../assets/img/home-icon/btn02.png');
      background-position: center right;
      background-repeat: no-repeat;
      background-size: 16px auto;
      &.code{
        background-image: url('../../assets/img/home-icon/btn01.png');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 16px auto;
      }
      &.home{
        padding-left: 20px;
        background-image: url('../../assets/img/home-icon/btn03.png');
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 16px auto;
      }
    }
  }
}
</style>