import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from "../components/layout/index.vue"

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push

// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, Location).catch(err => err)
// }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    //component: ressolve => require()
    //component: resolve => require(['../views/Home.vue'],resolve) 
  },
  {
    path: '/case',
    name: 'Case',
    component: Layout,
    redirect: { name: 'CaseList' },
    meta: {
      title: '解决方案'
    },
    children: [
      { 
        path: '/case_list/:pid?',
        name: 'CaseList',
        component: () => import(/* webpackChunkName: "case-list" */ '../views/Case.vue')
        //component: resolve => require(['../views/Case.vue'],resolve) 
      },
      {
        path: '/case_Detail/:id',
        name: 'CaseDetail',
        component: () => import(/* webpackChunkName: "case-detail" */ '../views/CaseDetail.vue')
        //component: resolve => require(['../views/CaseDetail.vue'],resolve) 
      },
    ]
  },
  {
    path: '/product',
    name: 'Product',
    component: Layout,
    redirect: { name: 'ProductList' },
    meta: {
      title: '产品介绍'
    },
    children: [
      {
        path: '/product_list/:pid?/:tid?',
        name: 'ProductList',
        //component: resolve => require(['../views/Production.vue'],resolve)
        component: () => import(/* webpackChunkName: "product-list" */ '../views/Production.vue')
      },
      {
        path: '/product_detail/:pid?/:tid?/:id',
        name: 'ProducDetail',
        //component: resolve => require(['../views/Pro-detail.vue'],resolve)
        component: () => import(/* webpackChunkName: "pro-detail" */ '../views/Pro-detail.vue')
      }
    ]
  },
  {
    path: '/typical_case',
    name: 'TypicalCase',
    component: Layout,
    redirect: { name: 'TypicalCaseList' },
    meta: {
      title: '典型案例'
    },
    children: [
      {
        path: '/typical_case_list',
        name: 'TypicalCaseList',
        //component: resolve => require(['../views/Typical_case.vue'],resolve)
        component: () => import(/* webpackChunkName: "research_detail" */ '../views/Typical_case.vue')
      },
      {
        path: '/typical_case_detail/:id?',
        name: 'TypicalCaseDetail',
        //component: resolve => require(['../views/Typical_case_detail.vue'],resolve)
        component: () => import(/* webpackChunkName: "research_detail" */ '../views/Typical_case_detail.vue')
      },
    ]
  },
  {
    path: '/research',
    name: 'Research',
    component: Layout,
    redirect: { name: 'ResearchList' },
    meta: {
      title: '研发服务'
    },
    children: [
      {
        path: '/research_list',
        name: 'ResearchList',
        component: () => import(/* webpackChunkName: "research" */ '../views/Research.vue')
        //component: resolve => require(['../views/Research.vue'],resolve)
      },
      {
        path: '/research_detail/:id',
        name: 'ResearchDetail',
        component: () => import(/* webpackChunkName: "research_detail" */ '../views/Research-detail.vue')
        //component: resolve => require(['../views/Research-detail.vue'],resolve)
      },
    ]
  },
  {
    path: '/news',
    name: 'News',
    component: Layout,
    redirect: { name: 'NewsList' },
    meta: {
      title: '新闻中心'
    },
    children: [
      {
        path: '/news_list',
        name: 'NewsList',
        component: () => import(/* webpackChunkName: "news-list" */ '../views/News.vue')
        //component: resolve => require(['../views/News.vue'],resolve)
      },
      {
        path: '/news_Detail/:id',
        name: 'NewsDetail',
        component: () => import(/* webpackChunkName: "news-detail" */ '../views/NewDetail.vue')
        //component: resolve => require(['../views/NewDetail.vue'],resolve)
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于我们'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    //component: resolve => require(['../views/About.vue'],resolve)
  },
  {
    path: '/recruit',
    name: 'Recruit',
    meta: {
      title: '招聘'
    },
    component: () => import(/* webpackChunkName: "recruit" */ '../views/Recruit.vue')
    //component: resolve => require(['../views/Recruit.vue'],resolve)
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition){
    return { x: 0, y: 0 }
  },
  routes
})

router.afterEach(() => {
  window.scrollTo(0,0)
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  console.log(document.body.scrollTop, document.documentElement.scrollTop, 'TTTTTT');
})

export default router
