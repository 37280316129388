<template>
  <div class="bread-nav">
    <span v-if="text">{{text}}</span>
    <span v-else>{{ $store.state.language == 'CN' ?  $store.state.breadNavList.name : $store.state.breadNavList.enname}}</span>
  </div>
</template>

<script>
export default {
  name: 'bread-nav',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.bread-nav{
  font-size: 14px;
  color: #6B6B6B;
  span{
    //cursor: pointer;
    &:hover{
      //color: #1E88FF;
    }
    &::after{
      content: '>';
      padding: 0 2px;
    }
    &:last-child{
      &::after{
        display: none;
      }
    }
  }
}
</style>