<template>
  <div class="footer">
    <!-- pc -->
    <div class="container footer-item" v-if="$store.state.clientWidth > 1200">
      <div class="item log">
          <img :src="$store.state.webBasicSet.icon" alt="">
      </div>
      <div class="item center">
        <div>
          <span :class="{'eng-text': $store.state.language == 'EN'}" :style="{'padding-left': $store.state.language == 'EN' ? '40px' : '', 'padding-right': $store.state.language == 'EN' ? '20px' : ''}">ADD: {{$store.state.language == 'CN' ? $store.state.webBasicSet.address : $store.state.webBasicSet.enaddress}}</span>
          <span :class="{'eng-text': $store.state.language == 'EN'}" :style="{'padding-left': $store.state.language == 'EN' ? '40px' : '', 'padding-right': $store.state.language == 'EN' ? '20px' : '' }">TEL: {{$store.state.webBasicSet.tel}}</span>
          <span :class="{'eng-text': $store.state.language == 'EN'}" :style="{'padding-left': $store.state.language == 'EN' ? '40px' : '', 'padding-right': $store.state.language == 'EN' ? '20px' : '' }">EMAIL：{{$store.state.webBasicSet.email}}</span>
        </div>
        <div class="top" :style="{'padding-top': $store.state.language == 'EN' ? '10px' : ''}">
          <span :style="{'padding-left': $store.state.language == 'EN' ? '40px' : '', 'padding-right': $store.state.language == 'EN' ? '20px' : ''}">
            {{$store.state.language == 'CN' ? '友情链接' : 'Friendly Links'}}:
          </span>
          <span :class="{'eng-text': $store.state.language == 'EN'}" :style="{'padding-left': $store.state.language == 'EN' ? '40px' : '', 'padding-right': $store.state.language == 'EN' ? '20px' : ''}"> 
            <a target="_blank" href="https://www.sslab.org.cn">{{$store.state.language == 'CN' ? '松山湖材料实验室' : 'Songshan Lake Materials Laboratory'}}</a> 
            <a target="_blank" href="http://www.innotitan.com">{{ $store.state.language == 'CN' ? '科大天工智能装备研究院' : 'INNOTITAN Smart Equipment Research Institute' }}</a>
          </span>
        </div>
      </div>
      <div class="item code">
        <div class="code-item">
          <span>{{ $store.state.language == 'CN' ? '扫一扫关注' : 'Scan and follow' }}</span><br> 
          <span>{{$store.state.language == 'CN' ? '中科华芯（东莞）'  : 'INNOSOC'}}</span>
          <img :src="$store.state.webBasicSet.qrcode" alt="">
        </div>
      </div>
    </div>
    <div class="copyright" v-if="$store.state.clientWidth > 1200">
      Copyright©2011-2022 {{$store.state.language == 'CN' ? $store.state.webBasicSet.name : $store.state.webBasicSet.enname}} 
      <a href="https://beian.miit.gov.cn/" target="_blank">
        粤ICP备2021164335号 
      </a>
      
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44190002006055" >
      <img src="../../assets/img/home-icon/asd.png"/>
      <span style="position: relative; top: -2px;">粤公网安备 44190002006055号</span>
      </a>
    </div>

    <!-- mobile -->
    <div class="mb-footer" v-if="$store.state.clientWidth <= 1200">
      <div class="log">
        <img :src="$store.state.webBasicSet.icon" alt="">
      </div>
      <div class="code">
        <span>{{$store.state.language == 'CN' ? '中科华芯（东莞）'  : 'INNOSOC'}}</span>
        <img :src="$store.state.webBasicSet.qrcode" alt="">
      </div>
      <div class="tel">
        <span>ADD:  {{$store.state.language == 'CN' ? $store.state.webBasicSet.address : $store.state.webBasicSet.enaddress}}</span>
        <span>TEL: 0769-22038565</span>
        <span>EMAIL：zkhx2021@yeah.net</span>
      </div>
      <div class="mb-copyright">
         Copyright©2011-2022 {{$store.state.language == 'CN' ? $store.state.webBasicSet.name : $store.state.webBasicSet.enname}}  <br> 
           <a href="https://beian.miit.gov.cn/" target="_blank">
        粤ICP备2021164335号 
      </a> <br>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44190002006055"><img src="../../assets/img/home-icon/asd.png"/>粤公网安备 44190002006055号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'h-footer'
}
</script>

<style lang="less" scoped>
@import url("./footer.less");
</style> 