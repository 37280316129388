<template>
  <div class="banner">
    <div class="b-text" v-if="text">{{text}}</div>
    <img v-lazy="imgUrl" alt="">
  </div>
</template>

<script>
export default {
  props: {
    imgUrl: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.banner{
  width: 100%;
  position: relative;
  .b-text{
    width: 100%;
    font-size: 30px;
    color: #fff;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-20px);
  }
  img{
    display: block;
    width: 100%;
  }
}
</style>