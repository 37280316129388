

let mixin = {
  data() {
    return {
      
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    push(name, query = {}) {
      this.$router.push({
        name,
        query
      }).catch(err => {
        console.log(err);
      })
    },
    switch(name, query = {}) {
      this.$router.replace({
        name,
        query
      })
    },
    ajax(parma) {
      var { type, url, data, responseType, success, errCallBack} = parma
      this.$http({
        method: type || 'post',
        url: url || '',
        data: data || '',
        responseType: responseType || '',
      }).then(res => {
        
        if(success) {
          success(res)
        }
      }).catch(error => {
        
        errCallBack && errCallBack(error)
      })
    },
    getBannerList() {
      this.ajax({
        url: '/website/banner/getlist',
        method: 'post',
        data: {
          limit: 30,
          start: 1,
          status: 0
        },
        success: res => {
          this.$store.dispatch('set_bannerList', res.data.rows);
        }
      })
    },
    getSubNavList() {
      this.ajax({
        url: '/website/introduce/getlist',
        method: 'post',
        data: {
          limit: 4,
          start: 1,
          status: 0
        },
        success: res => {
          this.$store.dispatch('set_subNavList', res.data.rows)
        }
      })
    },
    getHomeDetail() {
      this.ajax({
        url: '/website/index/getDetail',
        success: res => {
          console.log('====================================');
          console.log(res.data, 'home');
          console.log('====================================');
          let {architecture, introduceList, aboutUs, partnersList, newsList} = res.data;
          let {endetail, title, entitle} = architecture;
          let data = {
            architecture: {
              title, 
              entitle,
              endetail: JSON.parse(endetail)
            },
            aboutUs,
            partnersList,
            newsList
          }
          this.$store.dispatch('set_homeDetail', data);
          this.$store.dispatch('set_subNavList', introduceList);

          console.log('====================================');
          console.log(this.$store.state.homeDetail, 'homeDetail');
          console.log('====================================');
        }
      })
    },
    // 获取网站设置
    getWebSet() {
      this.ajax({
        url: '/website/baseConfig/getDetail',
        success: res => {
          this.$store.dispatch('set_webBasicSet', res.data);
        }
      })
    },
    // 导航
    getNavList() {
      this.ajax({
        url: '/website/navigation/getlist',
        data: {
          status: 0
        },
        success: res => {
          res.data.forEach(item => {
            if(item.type == 1) {
              item.children.forEach(el => {
                el.level = 2;
              })
            }
          });
          this.$store.dispatch('set_newNavlist', res.data);
        }
      })
    },
    formatDate (time, fmt) {
      var date = new Date(time)
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      var o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (var k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          var str = o[k] + ''
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
        }
      }
      return fmt
    },
    padLeftZero (str) {
      return ('00' + str).substr(str.length)
    },
  },
  created() {

  },
}

export default mixin;