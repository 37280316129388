import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navList: [
      { title: '首页', code: 'Home', childer: [], leve: 1},
      { 
        title: '产品介绍',
        code: 'Product',
        leve: 1,
        childer: [
          {
            icon:'https://innosoc2-1308345741.cos.ap-guangzhou.myqcloud.com/icon%2Fcase-icon01.png',
            title: '芯片及模组',
            code: 'Product',
            type: 1,
            leve: 2,
            childer: [
              {
                icon: '',
                title: '传感器芯片',
                code: 'Product',
                type: 7,
                parentType: 1,
                childer: []
              },
              {
                icon: '',
                title: '通信模组',
                code: 'Product',
                type: 8,
                parentType: 1,
                childer: []
              }
            ]
          },
          {
            icon:'https://innosoc2-1308345741.cos.ap-guangzhou.myqcloud.com/icon%2Fcase-icon02.png',
            title: '传感器',
            type: 2,
            code: 'Product',
            leve: 2,
            childer: [
              {
                icon: '',
                title: '建筑安全',
                code: 'Product',
                parentType: 2,
                childer: [],
                type: 9,
              },
              {
                icon: '',
                title: '汽车电子',
                code: 'Product',
                parentType: 2,
                childer: [],
                type: 10,
              }
            ]
          },
          {
            icon:'https://innosoc2-1308345741.cos.ap-guangzhou.myqcloud.com/icon%2Fcase-icon03.png',
            title: '边缘设备',
            type: 3,
            code: 'Product',
            leve: 2,
            childer: [
              {
                icon: '',
                title: '边缘计算网关',
                code: 'ProducDetail',
                parentType: 3,
                childer: [],
                type: 11,
                id: 7
              },
              {
                icon: '',
                title: '无线采集节点',
                code: 'ProducDetail',
                parentType: 3,
                childer: [],
                type: 12,
                id: 6
              },
              {
                icon: '',
                title: '边缘控制器',
                code: 'ProducDetail',
                parentType: 3,
                childer: [],
                type: 13,
                id: 40
              }
            ]
          },   
          {
            icon:'https://innosoc2-1308345741.cos.ap-guangzhou.myqcloud.com/icon%2Fcase-icon04.png',
            title: '物联网应用平台',
            type: 4,
            code: 'Product',
            leve: 2,
            childer: [
              {
                icon: '',
                title: '物联网数据中台',
                code: 'ProducDetail',
                childer: [],
                parentType: 4,
                type: 14,
                id: 8,
              },
              {
                icon: '',
                title: '数据分析及诊断引擎',
                code: 'ProducDetail',
                type: 15,
                parentType: 4,
                childer: [],
                id: 9,
              },
              {
                icon: '',
                title: '企业级管理系统',
                code: 'ProducDetail',
                type: 16,
                id: 10,
                parentType: 4,
                childer: []
              },
              {
                icon: '',
                title: '数据可视化',
                code: 'ProducDetail',
                type: 17,
                parentType: 4,
                id: 11,
                childer: []
              }
            ]
          },
        ],
      },
      { 
        title: '解决方案', 
        code: 'Case',
        icon: '',
        leve: 1,
        childer: [
          {
            icon:'https://innosoc2-1308345741.cos.ap-guangzhou.myqcloud.com/icon%2Fcase-icon05.png',
            title: '建筑安全',
            code: 'Case',
            leve: 2,
            type: 1,
            childer: [
              {
                icon: '',
                title: '建筑安全监管平台',
                code: 'CaseDetail',
                parentType: 1,
                type: 2,
                childer: [],
                id: 1
              },
              {
                icon: '',
                title: '轨道交通基础设施监测',
                code: 'CaseDetail',
                parentType: 1,
                type: 3,
                childer: [],
                id: 2
              },
              {
                icon: '',
                title: '综合管廊监测',
                code: 'CaseDetail',
                parentType: 1,
                type: 4,
                childer: [],
                id: 3
              }
            ]
          }
        ] 
      },
      { title: '研发服务', code: 'Research', childer: [], leve: 1, },
      { 
        title: '典型案例',
        code: 'TypicalCase', 
        leve: 1,
        childer:[
          {
            icon:'',
            title: '建筑安全监管平台',
            code: 'TypicalCaseDetail',
          }
        ] 
      },
      { title: '新闻中心', code: 'News', childer: [], leve: 1, },
      { title: '关于我们', code: 'About', childer: [], leve: 1,},
      { title: '招聘',  code: 'Recruit', childer: [], leve: 1,}
    ],
    clientWidth: '375',
    showMenu: false,
    mbMenuList: [],
    breadNavList: {},
    // 是否是横屏
    horizontalScreen: true,
    language: 'CN',
    bannerList: [],
    subNavList: [],
    homeDetail: {},
    webBasicSet: {},
    newNavlist: [],
    isWebShow: false
  },
  getters: {
    // 获取首页banner
    getBanner: (state) => {
      return (location) => state.bannerList.filter(item => item.location == location)
    },
    // 获得二级页面的banner
    getSubBanner:  (state) => {
      return (location) => state.bannerList.filter(item => item.location == location)[0]
    },
    // 分别取首页数据
    getHomeData: (state) => {
      return (prop) => state.homeDetail[prop]
    }
  },
  mutations: { 
    setClientWidth(state, w) {
      state.clientWidth = w;
    },
    setShowMenu(state, flag) {
      state.showMenu = flag
    },
    SetmbMenuList(state, list) {
      if(list.length) {
        state.mbMenuList = list
      } else {
        state.mbMenuList = [];
      }
    },
    setBreadNavList(state, obj) {
      state.breadNavList = obj;
    },
    setHorizontalScreen(state, flag) {
      state.horizontalScreen = flag;
    },
    setLanguage(state, str) {
      state.language = str;
    },
    setBannerList(state, list) {
      state.bannerList = list;
    },
    setSubNavList(state, list) {
      state.subNavList = list;
    },
    setHomeDetail(state, obj) {
      state.homeDetail = obj;
    },
    setWebBasicSet(state, obj) {
      state.webBasicSet = obj;
    },
    setNewNavlist(state, list) {
      state.newNavlist = list;
    }
  },
  actions: {
    set_clientWidth({commit}, w) {
      commit('setClientWidth', w)
    },
    set_showMenu({commit}, flag) {
      commit('setShowMenu', flag)
    },
    set_mbMenuList({commit}, list) {
      commit('SetmbMenuList', list)
    },
    set_breadNavList({commit}, obj) {
      commit('setBreadNavList', obj)
    },
    set_horizontalScreen({commit}, flag) {
      commit('setHorizontalScreen', flag)
    },
    set_language({commit}, str) {
      commit('setLanguage', str)
    },
    set_bannerList({commit}, list) {
      commit('setBannerList', list)
    },
    set_subNavList({commit}, list) {
      commit('setSubNavList', list)
    },
    set_homeDetail({commit}, obj) {
      commit('setHomeDetail', obj)
    },
    set_webBasicSet({commit}, obj) {
      commit('setWebBasicSet', obj)
    },
    set_newNavlist({commit}, list) {
      commit('setNewNavlist', list)
    }
  },
  modules: {
  }
})
